.Main .main_stream {
	height: 550px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}

.Main.mobile .main_stream {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%;
	/* min-height: 100%; */
	height: calc((100vw - 20px) * 720/ 1280);
	max-height: 100vh;
	max-width: 100%;

}

.Main .lecteur {
	width: 973.5px;
	height: 100%;

}

.Main .lecteur iframe {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	border: none;
}


.Main .list {
	width: 100%;
	margin-top: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.Main .list .list-title {
	width: 100%;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
}

.Main.mobile .list .list-title {
	flex-direction: column;
}

.Main.mobile .list .list-title input {
	width: 100%;
	height: 40px;
}

.Main.mobile .list .list-title p {
	width: 100%;
	height: 40px;
}

.Main .list .list-title input {
	background-color: #2d2e34;
	border: none;
	height: 30px;
	width: 15%;
	padding: 5px;
	color: white;
	border-radius: 8px;
}


.Main .list .list-title p {
	color: white;
	font-weight: 500;
	font-size: 22px;
}

.Main .list .lists {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 15px;
	grid-row-gap: 15px;
	width: 100%;
}

.Main.mobile .list .lists {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
}

.Main.mobile .list .lists.landscape {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
}

.Main .list .lists .listItem {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
}

.Main .list .lists .listItem.active {
	border: 2px solid green;
	border-radius: 20px;
}


.Main .list .lists .listItem .itemThumbnail,
.Main .list .lists .listItem .itemThumbnail img {
	width: 100%;
	height: 100%;
	border-radius: 20px 20px 0px 0px;
}

.Main .list .lists .listItem .itemStreamInfo {
	width: 100%;
	padding: 15px;
	border-radius: 0px 0px 20px 20px;
	background: #2d2e34;
	color: white;
}

.Main.mobile .list .lists .listItem .itemStreamInfo {
	padding: 10px;
}

.Main .list .lists .listItem .itemStreamInfo>div:first-child {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.Main.mobile .list .lists .listItem .itemStreamInfo>div:first-child {
	flex-direction: column;
}

.Main .list .lists .listItem .itemStreamInfo .streamer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	width: 100%;
}


.Main .list .lists .listItem .itemStreamInfo .profilePicture {
	width: 20px;
	height: 20px;
	background: red;
	border-radius: 50%;
	margin-right: 10px;
}

.Main .list .lists .listItem .itemStreamInfo .name {
	font-weight: 100;
	font-size: 16;
}


.Main .list .lists .listItem .itemStreamInfo .nbViewer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: row;
	width: 100%;
}


.Main.mobile .list .lists .listItem .itemStreamInfo .nbViewer {
	justify-content: flex-start;
	margin-top: 5px;
}

.Main .list .lists .listItem .itemStreamInfo .nbViewer .nbtitle {
	font-weight: 100;
	font-size: 16;
	font-weight: 100;
}