.Multiview {
	width: 85%;
	height: 100vh;
	background-color: #1B1C22;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0px;
	flex-direction: column;
	overflow: hidden;
}


.Multiview.mobile {
	width: 100%;
	padding: 10px;
}


.Multiview .topBar {
	width: 100%;
	height: 5%;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 0 5px 0 5px;
	color: white;
	border-bottom: 1px solid #3a3a3a;
}

.Multiview .lists {
	width: 100%;
	height: 95%;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
}

.Multiview.mobile .lists {
	width: 100%;
	height: 95%;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.Multiview .livestream {
	width: 100%;
}

.Multiview .livestream iframe {
	border: none;
}

/**** 1 ****/
.Multiview .lists[data-nb="1"] .livestream[data-stream="0"] {
	width: 100%;
	height: 100%;
}

/**** 2 ****/
.Multiview .lists[data-nb="2"] .livestream[data-stream="0"] {
	width: 100%;
	height: 60%;
}

.Multiview .lists[data-nb="2"] .livestream[data-stream="1"] {
	width: 100%;
	height: 40%;
}

.Multiview.mobile .lists[data-nb="2"].landscape .livestream[data-stream="0"] {
	width: 50%;
	height: 100%;
}

.Multiview.mobile .lists[data-nb="2"].landscape .livestream[data-stream="1"] {
	width: 50%;
	height: 100%;
}


/**** 3 ****/
.Multiview .lists[data-nb="3"] .livestream[data-stream="0"] {
	width: 100%;
	height: 60%;
}

.Multiview .lists[data-nb="3"] .livestream[data-stream="1"] {
	width: 50%;
	height: 40%;
}

.Multiview .lists[data-nb="3"] .livestream[data-stream="2"] {
	width: 50%;
	height: 40%;
}

.Multiview.mobile .lists[data-nb="3"] .livestream[data-stream="0"] {
	width: 100%;
	height: 33%;
}

.Multiview.mobile .lists[data-nb="3"] .livestream[data-stream="1"] {
	width: 100%;
	height: 33%;
}

.Multiview.mobile .lists[data-nb="3"] .livestream[data-stream="2"] {
	width: 100%;
	height: 33%;
}


.Multiview.mobile .lists[data-nb="3"].landscape .livestream[data-stream="0"] {
	width: 33%;
	height: 100%;
}

.Multiview.mobile .lists[data-nb="3"].landscape .livestream[data-stream="1"] {
	width: 33%;
	height: 100%;
}

.Multiview.mobile .lists[data-nb="3"].landscape .livestream[data-stream="2"] {
	width: 33%;
	height: 100%;
}


/**** 4 ****/
.Multiview .lists[data-nb="4"] .livestream[data-stream="0"] {
	width: 50%;
	height: 50%;
}

.Multiview .lists[data-nb="4"] .livestream[data-stream="1"] {
	width: 50%;
	height: 50%;
}

.Multiview .lists[data-nb="4"] .livestream[data-stream="2"] {
	width: 50%;
	height: 50%;
}

.Multiview .lists[data-nb="4"] .livestream[data-stream="3"] {
	width: 50%;
	height: 50%;
}

.Multiview.mobile .lists[data-nb="4"] .livestream[data-stream="0"] {
	width: 100%;
	height: 25%;
}

.Multiview.mobile .lists[data-nb="4"] .livestream[data-stream="1"] {
	width: 100%;
	height: 25%;
}

.Multiview.mobile .lists[data-nb="4"] .livestream[data-stream="2"] {
	width: 100%;
	height: 25%;
}

.Multiview.mobile .lists[data-nb="4"] .livestream[data-stream="3"] {
	width: 100%;
	height: 25%;
}

.Multiview.mobile .lists[data-nb="4"].landscape .livestream[data-stream="0"] {
	width: 50%;
	height: 50%;
}

.Multiview.mobile .lists[data-nb="4"].landscape .livestream[data-stream="1"] {
	width: 50%;
	height: 50%;
}

.Multiview.mobile .lists[data-nb="4"].landscape .livestream[data-stream="2"] {
	width: 50%;
	height: 50%;
}

.Multiview.mobile .lists[data-nb="4"].landscape .livestream[data-stream="3"] {
	width: 50%;
	height: 50%;
}

/**** 5 ****/
.Multiview .lists[data-nb="5"] .livestream[data-stream="0"] {
	width: 50%;
	height: 50%;
}

.Multiview .lists[data-nb="5"] .livestream[data-stream="1"] {
	width: 50%;
	height: 50%;
}

.Multiview .lists[data-nb="5"] .livestream[data-stream="2"] {
	width: 33.33%;
	height: 50%;
}

.Multiview .lists[data-nb="5"] .livestream[data-stream="3"] {
	width: 33.33%;
	height: 50%;
}

.Multiview .lists[data-nb="5"] .livestream[data-stream="4"] {
	width: 33.33%;
	height: 50%;
}

/**** 6 ****/
.Multiview .lists[data-nb="6"] .livestream[data-stream="0"] {
	width: 33.33%;
	height: 50%;
}

.Multiview .lists[data-nb="6"] .livestream[data-stream="1"] {
	width: 33.33%;
	height: 50%;
}

.Multiview .lists[data-nb="6"] .livestream[data-stream="2"] {
	width: 33.33%;
	height: 50%;
}

.Multiview .lists[data-nb="6"] .livestream[data-stream="3"] {
	width: 33.33%;
	height: 50%;
}

.Multiview .lists[data-nb="6"] .livestream[data-stream="4"] {
	width: 33.33%;
	height: 50%;
}

.Multiview .lists[data-nb="6"] .livestream[data-stream="5"] {
	width: 33.33%;
	height: 50%;
}

/**** 7 ****/
.Multiview .lists[data-nb="7"] .livestream[data-stream="0"] {
	display: block;
	width: 33.33%;
	height: 60%;
	left: 0%;
	top: 0%;
}

.Multiview .lists[data-nb="7"] .livestream[data-stream="1"] {
	display: block;
	width: 33.33%;
	height: 60%;
	left: 33%;
	top: 0%;
}

.Multiview .lists[data-nb="7"] .livestream[data-stream="2"] {
	display: block;
	width: 33.33%;
	height: 60%;
	left: 67%;
	top: 0%;
}

.Multiview .lists[data-nb="7"] .livestream[data-stream="3"] {
	display: block;
	width: 25%;
	height: 40%;
	left: 00%;
	top: 60%;
}

.Multiview .lists[data-nb="7"] .livestream[data-stream="4"] {
	display: block;
	width: 25%;
	height: 40%;
	left: 25%;
	top: 60%;
}

.Multiview .lists[data-nb="7"] .livestream[data-stream="5"] {
	display: block;
	width: 25%;
	height: 40%;
	left: 50%;
	top: 60%;
}

.Multiview .lists[data-nb="7"] .livestream[data-stream="6"] {
	display: block;
	width: 25%;
	height: 40%;
	left: 75%;
	top: 60%;
}

/**** 8 ****/
.Multiview .lists[data-nb="8"] .livestream[data-stream="0"] {
	display: block;
	width: 25%;
	height: 50%;
	left: 0%;
	top: 0%;
}

.Multiview .lists[data-nb="8"] .livestream[data-stream="1"] {
	display: block;
	width: 25%;
	height: 50%;
	left: 25%;
	top: 0%;
}

.Multiview .lists[data-nb="8"] .livestream[data-stream="2"] {
	display: block;
	width: 25%;
	height: 50%;
	left: 50%;
	top: 0%;
}

.Multiview .lists[data-nb="8"] .livestream[data-stream="3"] {
	display: block;
	width: 25%;
	height: 50%;
	left: 75%;
	top: 00%;
}

.Multiview .lists[data-nb="8"] .livestream[data-stream="4"] {
	display: block;
	width: 25%;
	height: 50%;
	left: 00%;
	top: 50%;
}

.Multiview .lists[data-nb="8"] .livestream[data-stream="5"] {
	display: block;
	width: 25%;
	height: 50%;
	left: 25%;
	top: 50%;
}

.Multiview .lists[data-nb="8"] .livestream[data-stream="6"] {
	display: block;
	width: 25%;
	height: 50%;
	left: 50%;
	top: 50%;
}

.Multiview .lists[data-nb="8"] .livestream[data-stream="7"] {
	display: block;
	width: 25%;
	height: 50%;
	left: 75%;
	top: 50%;
}







.option {
	position: absolute;
	top: 0%;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(28 19 19 / 50%);
	z-index: 11;
	display: flex;
	align-items: center;
	justify-content: center;
}

.option .container {
	background-color: #1B1C22;
	border-radius: 4px;
	width: 450px;
	height: 600px;
	z-index: 10;
	border: 1px solid #6b6b6b;
	display: flex;
	flex-direction: column;
	padding: 15px;
	-webkit-animation: show-popup 0.1s ease-in;
	animation: show-popup 0.1s ease-in;
	color: white;
}

.Multiview.mobile .option.landscape .container {
	width: 450px;
	height: 337px;
}

.Multiview.mobile .option .container {
	width: 400px;
	height: 500px;
}

.option .container.fade {
	-webkit-animation: hide-popup 0.1s ease-out;
	animation: hide-popup 0.1s ease-out;
}

.Multiview.mobile .option.landscape .container .header {
	padding: 0px;
}

.option .container .header {
	width: 100%;
	height: 10%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	/* padding-bottom: 15px; */
	/* border-bottom : 1px solid #6b6b6b; */
	padding: 15px;
}

.option .container .header svg {
	cursor: pointer;
}

.option .container .header .header_title h2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
}

.option .container .body {
	width: 100%;
	height: 90%;
}

.option .container .body .form {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
}

.option .container .body .form .liste {
	width: 100%;
	height: 90%;
}

.option .container .body .form .liste select {
	width: 100%;
	height: 35px;
	background-color: transparent;
	border: 2px solid #6b6b6b;
	border-radius: 4px;
	font-size: 15px;
	color: white;
}

.option .container .body .form .liste select option {
	background-color: #1B1C22;
	border: none;
	color: white;
	padding: 15px;
}

/***Button pannel***/
.option .container .body .form .button_pannel {
	width: 100%;
	height: 20%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.option .container .body .form .button_pannel>div {
	width: 35%;
}

.option .container .body .form .button_pannel button {
	width: 100%;
	height: 45px;
	background: transparent;
	border: 1px solid;
	border-radius: 5px;
	letter-spacing: 0.09rem;
	cursor: pointer;
}

.option .container .body .form .button_pannel button.cancel {
	border-color: #6b6b6b;
	color: #6b6b6b;
}

.option .container .body .form .button_pannel button.save {
	background-color: #3D50FA;
	border-color: #3D50FA;
	color: white;
}


@-webkit-keyframes show-popup {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes show-popup {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes hide-popup {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes hide-popup {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}