* {
  margin: 0;
  padding: 0;
  font-family: Nunito, Roboto, Arial, sans-serif;
  box-sizing: border-box
}

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
}

.sidebar {
  width: 15%;
  height: 100vh;
  background-color: #191A1F;
  border-right: 1px solid #414247;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: "space-between";
}

.sidebar.mobile {
  display: none;
}

.sidebar.mobile.menuOpen {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.sidebar>div {
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.sidebar .sidebar-top {
  justify-content: flex-start;
}

.sidebar .sidebar-bottom {
  justify-content: flex-end;
}

.Main {
  width: 85%;
  height: 100vh;
  background-color: #1B1C22;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 25px;
  flex-direction: column;
}

.Main.mobile {
  width: 100%;
  padding: 10px;
}


.Main .main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}