.sidebar-logo,
.sidebar-reseaux {
	width: 100%;
	height: 15%;
	border-top: 1px solid #3a3a3a;
}

.sidebar-logo>div {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sidebar-logo>div>img {
	width: 25%;
}

.sidebar.mobile .sidebar-logo>div>img {
	max-width: 50px;
}


.sidebar-reseaux {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: row;
}

.sidebar-reseaux .logoReseau {
	width: 33%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sidebar-reseaux .logoReseau a {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sidebar-reseaux .logoReseau a svg {
	width: 33%;
	cursor: pointer;
}

.sidebar-menu {
	width: 100%;
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 25px auto 0 auto;
}


.menu_pages {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	background-color: transparent;
	width: 100%;
	border-radius: 8px;
	cursor: pointer;
}

.menu_pages a {
	display: flex;
	align-items: center;
	padding: 10px 0px;
	/* margin-bottom: 15px; */
	background-color: transparent;
	width: 100%;
	border-radius: 8px;
	cursor: pointer;
}

.menu_pages:hover,
.menu_pages.selectMenu {
	background-color: #3D50FA;
}

.menu_pages .menu_icon {
	flex: 30%;
	display: grid;
	place-items: center;
}

.menu_pages .menu_text {
	flex: 70%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.menu_text p {
	font-size: 18px;
	font-weight: 500;
}

.sidebar-menu a {
	text-decoration: none;
	color: white;
}

.sidebar-menu .icon,
.menu_text p {
	color: #5E5F62;
}

.menu_pages.selectMenu .icon,
.menu_pages:hover p,
.menu_pages:hover .icon,
.menu_pages.selectMenu p {
	color: white;
}

.menu_title {
	margin-bottom: 5px;
	display: flex;
	align-items: flex-start;
	width: 80%;
}

.menu_title p {
	color: #5E5F62;
	font-weight: 500;
}

.menu {
	width: 80%;
}